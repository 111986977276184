<template>
  <el-dialog
    title="关于"
    class="about__dialog"
    :visible="value"
    @close="$emit('input', false)"
    width="30%"
    center
  >
    <div style="text-align: center">
      <h3>一款高度简洁且的微信 Markdown 编辑器和图片生成器</h3>
    </div>
    <div style="text-align: center; margin-top: 10px">
      <p>常用资源推荐</p>
      <p>
        <a class="link" target="_blank" href="https://www.marscode.cn/events/s/iSfxCSLX/">豆包MarsCode编程助手</a>
        <a class="link" target="_blank" href="https://tongyi.aliyun.com/lingma/pricing?spm=5176.29345612.J_xtJf7NKCVIlw0-kJFNN8A.3.52f324f8YyQvkZ&userCode=okjhlpr5">通义编程助手</a>
      </p>
      <p>
        <a class="link" target="_blank" href="https://www.aliyun.com/minisite/goods?spm=5176.29345612.J_xtJf7NKCVIlw0-kJFNN8A.2.52f324f8YyQvkZ&userCode=okjhlpr5">阿里云服务器79/台</a>
        <a class="link" target="_blank" href="https://curl.qcloud.com/5BzOh0Ri">腾讯云2核2G 128</a>
      </p>

<!--      <p>扫码关注公众号，更多好玩的及时推送，还有大厂内推！</p>-->
<!--      <p><a target="_blank" href="https://curl.qcloud.com/92ixxSWq" style="color: cornflowerblue">本服务托管于2核4G 3年198的腾讯云。</a></p>-->
<!--      <img-->
<!--        src="https://images.redream.cn/upic/2019/20220103140517-qrcode_for_gh_27101e9a2f9d_258%20%282%29.jpg"-->
<!--        style="width: 40%; display: block; margin: 20px auto 10px"-->
<!--      />-->
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button
          type="primary"
          @click="onRedirect('https://chromewebstore.google.com/detail/%E7%BD%91%E9%A1%B5%E8%BD%ACmarkdown/ngccckdpjacopmhmlanmmmncgfjnenac?hl=zh-CN')"
          plain
      >网页转markdown</el-button
      >
      <el-button
        type="primary"
        @click="onRedirect('https://chromewebstore.google.com/detail/%E5%A4%96%E9%93%BE%E7%9B%B4%E8%BE%BE-%E4%B8%80%E9%94%AE%E8%B7%B3%E8%BF%87%E5%BE%AE%E4%BF%A1%E3%80%81%E7%9F%A5%E4%B9%8E%E3%80%81%E6%8E%98%E9%87%91%E3%80%81%E7%AE%80%E4%B9%A6%E7%AD%89%E7%BD%91%E7%AB%99%E5%A4%96%E9%93%BE/mngjnofgcfllpfmgijahnfgalflkkhgl?hl=zh-CN')"
        plain
        >外链直达</el-button
      >
      <el-button
        type="primary"
        @click="onRedirect('https://chromewebstore.google.com/detail/kimi-sidebar-kimi%E4%BE%A7%E8%BE%B9%E6%A0%8F%E5%8A%A9%E6%89%8B/lieifjiddifccacnhgcafeebpahodebe')"
        plain
        >Kimi侧边栏助手</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onRedirect(url) {
      window.open(url);
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog {
  min-width: 480px;
}

.link{
  margin-right: 10px;
  color: #409EFF;

}
</style>
